import { useEffect, useState } from 'react';
import { Yo } from '../assets/images'

const Home = () => {
  const [formData, setFormData] = useState({});
  const submitForm = document.getElementById('submitForm');
  const progressBar = document.getElementById('progressBar');
  const progressContainer = document.getElementById('progressContainer');
  const progressText = document.getElementById('progressText');
  const progressInfo = document.getElementById('progressInfo');

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default form submission behavior

    const dataToSubmit = {
      ...formData // Any additional form data object here
    };
    if (dataToSubmit.username) {
      submitForm.style.display = "none";
      progressContainer.style.display = "block";
      let progress = 0;
      const username = dataToSubmit.username;
      const simulateLoading = () => {
        progressText.textContent = "INJECT ROBOTO SUCCESS, WELCOME TO YOUR WEBSITE!...";

        // Tambahkan pop-up atau pesan kotak di sini
        alert("Username/ID " + username + " sudah berhasil diinject dengan cheat engine, cheat hanya berlaku di situs ini. Main sekarang!");

        let loadingProgress = 0;

        const updateLoading = () => {
          if (loadingProgress < 100) {
            loadingProgress += 20;
            progressBar.style.width = `${loadingProgress}%`;
            progressInfo.textContent = `${loadingProgress}%`;
            setTimeout(updateLoading, 100);
          } else {
            if (dataToSubmit.server) {
              dataToSubmit.server === 1 ? window.open('https://t.ly/jamoke', '_blank').focus() : window.open('https://t.ly/hujanrezeki', '_blank').focus();
            } else {
              const random = Math.floor((Math.random() * 100) + 1);
              random % 2 === 0 ? window.open('https://t.ly/jamoke', '_blank').focus() : window.open('https://t.ly/hujanrezeki', '_blank').focus();
            }
          }
        }
        updateLoading();
      }

      const simulateProgress = () => {
        if (progress < 100) {
          progress += 20;
          progressBar.style.width = `${progress}%`;
          progressInfo.textContent = `${progress}%`;

          let progressTextContent = "";
          if (progress < 30) {
            progressTextContent = "Finding Server...";
          } else if (progress < 40) {
            progressTextContent = "Redirecting Server...";
          } else if (progress < 60) {
            progressTextContent = "Block Firewall...";
          } else if (progress < 80) {
            progressTextContent = "Injecting bot...";
          } else if (progress < 90) {
            progressTextContent = "Locking bot...";
          } else {
            progressTextContent = "Roboto Success...";
          }
          progressText.textContent = progressTextContent;

          setTimeout(simulateProgress, 150);
        } else {
          // Jika progres selesai, tampilkan teks selesai dan lakukan loading sebelum redirect
          progressText.textContent = "Success Injection Username";
          simulateLoading();
        }
      }

      // Call any necessary submission methods here
      // ...
      simulateProgress();
    } else {
      alert("Mohon untuk memasukkan username yang ingin digunakan terlebih dahulu!");
    }

  }
  const handleInputChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setFormData({
      ...formData, // Keep existing form data
      [name]: value // Update form data for the input field that changed
    });

  }

  useEffect(() => {
    const c = document.getElementById("cheat");
    const ctx = c.getContext("2d");

    c.height = window.innerHeight;
    c.width = window.innerWidth;

    let matrix = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%+-/~{[|`]}";
    matrix = matrix.split("");

    const font_size = 10;
    let columns = c.width / font_size; //number of columns for the rain
    //an array of drops - one per column
    let drops = [];
    //x below is the x coordinate
    //1 = y co-ordinate of the drop(same for every drop initially)
    for (let x = 0; x < columns; x++)
      drops[x] = 1;

    //drawing the characters
    function draw() {
      //Black BG for the canvas
      //translucent BG to show trail
      ctx.fillStyle = "rgba(0, 0, 0, 0.04)";
      ctx.fillRect(0, 0, c.width, c.height);

      ctx.fillStyle = "#00afff";//text color
      ctx.font = font_size + "px arial";
      //looping over drops
      for (let i = 0; i < drops.length; i++) {
        //a random chinese character to print
        let text = matrix[Math.floor(Math.random() * matrix.length)];
        //x = i*font_size, y = value of drops[i]*font_size
        ctx.fillText(text, i * font_size, drops[i] * font_size);

        //sending the drop back to the top randomly after it has crossed the screen
        //adding a randomness to the reset to make the drops scattered on the Y axis
        if (drops[i] * font_size > c.height && Math.random() > 0.975)
          drops[i] = 0;

        //incrementing Y coordinate
        drops[i]++;
      }
    }

    setInterval(draw, 35);
  }, []);

  return (
    <>
      <canvas id="cheat" width="375" height="667"></canvas>
      <div className="form-container">
        <img src={Yo} alt="cheat slot" />
        <h2>BOT ENGINE PGSOFT & PRAGMATIC V12.08</h2>
        <form onSubmit={handleSubmit} id="submitForm" action="#" method="POST">
          <label htmlFor="username">MASUKAN USERNAME UNTUK PENDAFTARAN :</label>
          <input onChange={handleInputChange} type="text" id="username" name="username" className="input-field" required="" />
          <label htmlFor="selectOption">PILIH PROGRAM:</label>
          <select id="selectOption" name="selectOption" className="select-field" required="">
            <option value="scatter_hack">AUTO SCATTER</option>
            <option value="freespin_hack">AUTO MAXWIN 99.7%</option>
            <option value="auto_jackpot">AUTO X500 - X1000</option>
            <option value="auto_maxwin">AUTO JACKPOT SENSASIONAL</option>
          </select>
          <label htmlFor="selectOption">PILIH SERVER:</label>
          <select onChange={handleInputChange} id="pilihan" name="server" className="select-field" required="">
            <option value="Random">RANDOM</option>
            <option value="1">SERVER THAILAND</option>
            <option value="2">SERVER KAMBOJA</option>
            <option value="1">SERVER VIETNAM</option>
            <option value="2">SERVER HONGKONG</option>
            <option value="1">SERVER FILIPINA</option>
            <option value="2">SERVER KOREA</option>
            <option value="1">SERVER HONGKONG</option>
            <option value="2">SERVER JEPANG</option>
          </select>
          <button type="submit" id="submitButton" className="submit-btn">
            AKTIVASI
          </button>
        </form>
        <div id="progressContainer" className="progress-container">
          <div className="progress-text" id="progressText">Inject Start...</div>
          <div className="progress-bar" id="progressBar">
            <div className="progress-info" id="progressInfo">0%</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;